
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import affTicket from "@/views/still/deploy/affTicket.vue";
import chat from "@/views/still/chat/chat.vue";
import { ElNotification } from 'element-plus'
import { useStore } from "vuex";
import SyntheseIntervenant from "@/views/still/fournisseur/syntheseIntervenant.vue";
import PlanningPresta from "@/views/still/fournisseur/planningPresta.vue";
// import { DatePicker } from 'v-calendar';

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    affTicket,
    chat,
    SyntheseIntervenant,
    // DatePicker,
    PlanningPresta
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {
    moment.default.locale("fr");
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      loaderEnabled: true,
      allContacts: [] as any,
      myList: [] as any,
      drawer: ref(false),
      drawerImport: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      search: "",
      ticketSelect: {} as any,
      valTis: 0,
      valTicket: {} as any,
      selTicket : 0,
      topChat : 95,
      hauteurChat: 124,
      loaderUpload : false,
      role_web : store.getters.currentUser.con_role_web,
      type_stat : 'tis_status',
      rangeFilter: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 5)
      }

    });
    const tableHeader = ref([
      {
        name: "Ticket",
        key: "tic_seq",
        sortable: true,
      },
      {
        name: "Planification",
        key: "tis_date_planif",
        sortable: true,
      },
      {
        name: "Statut",
        key: "tis_status",
        sortable: true,
      },
      {
        name: "Fichiers",
        key: "total_files",
        sortable: true,
      },
      {
        name: "Actions",
        key: "tic_knum_societe",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Statut",
        key: "tic_c_status",
        label: "tic_c_status",
      },
      {
        name: "Client",
        key: "soc_nom_societe",
        label: "soc_nom_societe",
      },
      {
        name: "Intervenant",
        key: "presta_nom_prenom",
        label: "presta_nom_prenom",
      },
      {
        name: "Planification",
        key: "presta_date_planif",
        label: "presta_date_planif",
      },
      {
        name: "Pièces jointes",
        key: "xfile",
        label: "xfile",
      },
    ]);

    const ExcelFormat = ref({
      "Ticket": "tic_seq",
      "Création": "tic_d_creation",  
      "Libellé du ticket": "tic_c_label",
      "Intervenant": "presta_nom_prenom",
      "Statut": "tis_status",
    });


    const handleClickPane = (tab: any, event: Event) => {
      console.log(tab, event)
    }

    const activeName = ref('first')

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_3_month = (d) => {
      const currentDate = moment.default().add(3, 'M');
      return moment.default(d) > currentDate;
    }

    const refreshRouter = () => {
      emit("refreshRouter" , 1);
    }

    const applyChange = async (data) => {

      if(data.tis_date_planif) data.tis_date_planif = moment.default(data.tis_date_planif).format("YYYY-MM-DD");

      mAxiosApi
        .post("updateInterDate", data)
        .then(async () => {

            const getTicketsPresta = await getAxios("/getTicketsPresta/"+ data.tis_seq);
              Object.keys(state.myList.filter(element=> element.tis_seq == data.tis_seq)[0]).forEach(function(key) {
              state.myList.filter(element=> element.tis_seq == data.tis_seq)[0][key] = getTicketsPresta.results[0][key];
            })
            state.myList.filter(element=> element.tis_seq == data.tis_seq)[0].old_tis_date_planif = state.myList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_date_planif;
            state.myList.filter(element=> element.tis_seq == data.tis_seq)[0].old_tis_knum_contact_presta = state.myList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_knum_contact_presta;
            /*
            ElNotification({
              title: 'Succès',
              message: 'Modification effectuée',
              type: 'success',
            })
            */

        })
        .catch((err) => {
          console.error(err);
        });

    };

    const affDetail = async (ticSeq, tisSeq = 0) => {

      const getTicketsPresta = await getAxios("/getTicketsPresta/"+ tisSeq);
        Object.keys(state.myList.filter(element=> element.tis_seq == tisSeq)[0]).forEach(function(key) {
        state.myList.filter(element=> element.tis_seq ==tisSeq)[0][key] = getTicketsPresta.results[0][key];
      })
      state.myList.filter(element=> element.tis_seq == tisSeq)[0].old_tis_date_planif = state.myList.filter(element=> element.tis_seq == tisSeq)[0].tis_date_planif;
      state.myList.filter(element=> element.tis_seq == tisSeq)[0].old_tis_knum_contact_presta = state.myList.filter(element=> element.tis_seq == tisSeq)[0].tis_knum_contact_presta;

      state.selTicket = ticSeq;
      state.valTis = tisSeq;
      let myList = await getAxios("/getTicket/" + ticSeq + "/" + tisSeq);
      myList.results.tic_staging.forEach((el) => {
        el.fichiers = JSON.parse(el.fichiers);
      })
      state.valTicket = myList.results;    
      state.drawer = true;
    };

    const affTicket = (data) => {
      affDetail(data.tic_seq, data.tis_seq);
    }

    const styleImgStatus = (data) => {
      if (data.tic_c_status == "Traité") {
        return "media/icons/duotune/arrows/arr085.svg";
      } else {
        return "media/icons/duotune/general/gen012.svg";
        
      }
    };

    const styleBtnStatus = (data) => {
      if (data.tic_c_status == "Traité") {
        return "btn-icon-success bg-white";
      } else {
         return "btn-icon-dark bg-white svg-icon svg-icon-sm-6 ";
      }
    };

    const dateChangePlanif= (data) => {

      if (!data.tis_date_planif) data.tis_date_planif = null;
      
      state.myList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_date_planif = data.tis_date_planif;
      state.initialMyList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_date_planif = data.tis_date_planif;

      applyChange(data);

    };

    const changeInter= (data) => {
      // console.log(state.myList.filter(element=> element.tis_seq == data.tis_seq));

      if (!data.tis_knum_contact_presta) data.tis_knum_contact_presta = null;

      state.myList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_knum_contact_presta = data.tis_knum_contact_presta;
      state.initialMyList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_knum_contact_presta = data.tis_knum_contact_presta;

      applyChange(data);

    };

    const compressImage = async (file, { quality = 1, type = file.type }) => {
        // Get as image data
        const imageBitmap = await createImageBitmap(file);

        // Draw to canvas
        const canvas = document.createElement('canvas');
        
        const typeImg = imageBitmap.width > imageBitmap.height ? "W" : "H";
        const typeSize = typeImg == "W" ? imageBitmap.width : imageBitmap.height;
        
        let ratio = 1;
        
       
       if (typeSize> 1800) {
        if (typeImg == "W" ) {
            ratio = imageBitmap.width / imageBitmap.height;
            canvas.width = 1800;
            canvas.height = 1800 / ratio;
        } else {
            ratio = imageBitmap.height / imageBitmap.width;
            canvas.height = 1800;
            canvas.width = 1800 / ratio;
        }
       }else {
          canvas.width = imageBitmap.width;
          canvas.height = imageBitmap.height;
       }


        const ctx = canvas.getContext('2d');
        if (ctx) {
          //ctx.drawImage(imageBitmap, 0,0);
          ctx.drawImage(imageBitmap, 0,0, imageBitmap.width, imageBitmap.height,0 ,0 , canvas.width, canvas.height); 
        } 

        // Turn into Blob
        return await new Promise((resolve) =>
            canvas.toBlob(resolve, type, quality)
        );
    };


    const submitUpload = async () => {
      state.loaderUpload = true;
      const file1: any = document.querySelector('input[type=file]');
      const file = file1.files[0];
      console.log('Image ?',file.type.startsWith('image'));
      if (file) {
          var reader = new FileReader();

          const compressedFile: any = await compressImage(file, {
              // 0: is maximum compression
              // 1: is no compression
              quality: 0.5,

              // We want a JPEG file
              type: 'image/jpeg',
          });


          reader.readAsDataURL(compressedFile);
          reader.onload = async function(e) {
              if(e.target){
                  //console.log(file.name, e.target.result);
            // We compress the file by 50%

                  console.log(e.target.result);

                  /* ICI */
                  const form = {
                    tif_knum_ticket: state.selTicket,
                    tif_knum_ticket_services: state.valTis,
                    files : [{ name: file.name, data: e.target.result }], //e.target.result
                  }
                  //console.log(form);
                  mAxiosApi
                    .post("/addFileTicketService", form)
                    .then( async() => {
                      affDetail(state.selTicket, state.valTis);
                      ElNotification({
                        title: 'Succès',
                        message: 'Fichier ajouté',
                        type: 'success',
                      })
                    })

              } 
          };
      }
    }

    const changeStatus = () => {
      alert('A DEV !');
    }

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Suivi des prestations", []);

      let myContacts = await getAxios("/getContacts");
      state.allContacts = myContacts.results;

      let myList = await getAxios("/getTicketsPresta");

      myList.results.forEach(
        function(item: any){
          if (item.tis_date_planif){
            item.tis_date_planif = moment.default(item.tis_date_planif);
          } 

          item.old_tis_date_planif = item.tis_date_planif;
          item.old_tis_knum_contact_presta = item.tis_knum_contact_presta;

        }
      );

      state.initialMyList = myList.results;
      state.myList = myList.results;      

      if (state.myList.length > 0){
        if (router.currentRoute.value.params.ticid) {
          affDetail(router.currentRoute.value.params.ticid);
        }
        if (router.currentRoute.value.params.status) {
          tableFilters.value.filter(el => el.key=="tic_c_status")[0]['default'] = [router.currentRoute.value.params.status];
        }
      }

      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      test_3_month,
      affDetail,
      tableFilters,
      ExcelFormat,
      refreshRouter,
      styleImgStatus,
      styleBtnStatus,
      dateChangePlanif,
      changeInter,
      applyChange,
      submitUpload,
      changeStatus,
      handleClickPane,
      activeName,
      affTicket
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
