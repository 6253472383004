<template>
  <transition name="fade">
    <div class="text-center section">
        <v-calendar
          class="custom-calendar max-w-full"
          :masks="masks"
          :attributes="state.attributes"
          is-expanded
        >
          <template v-slot:day-content="{ day, attributes }">
            <div class="flex flex-col h-full z-10" style="overflow: auto; height: 100%;">
              <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
              <div class="flex-grow overflow-y-auto overflow-x-auto">
                <template v-for="attr in attributes" :key="attr.key">
                  <el-tooltip :content="attr.customData.tooltip" placement="top" effect="light" :show-after="500">
                    <a 
                      @click="clickTicket(attr.customData.item)"
                      class="btn btn-sm btn-light-primary btn-active-primary me-2 mb-2" style="width: 100%;overflow: hidden; white-space: nowrap; height: 30px; padding: 5px">
                        {{ attr.customData.title }}
                    </a>
                  </el-tooltip>
                </template>
              </div>
            </div>
          </template>
        </v-calendar>
      </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import * as moment from "moment";

export default defineComponent({
  name: "kt-activite",
  props: {
    widgetClasses: String,
    myList: {default : [] as any },
    mField: String,
  },
  emits: ["affTicket"],
  setup(props, { emit }) {

  moment.default.locale("fr");

    const state = reactive({
      list: [] as any,
      loaderEnabled: true,
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      masks: {
        weekdays: 'WWW',
      },
      attributes: [] as any,

    });

    const zmyList = props.myList as any;
    const zmField = props.mField as any;

    const clickTicket = (data) => {
      console.log(data)
      emit("affTicket", data);
    }

    onMounted(async () => {

      zmyList.forEach(
        function(item: any){
          if(item.tis_date_planif) {
            // console.log(item.tis_date_planif, moment.default(item.tis_date_planif).toDate())
            state.attributes.push(
              {
                key: item.tis_seq,
                customData: {
                  title: ""+item.tic_c_label + "",
                  item: item,
                  tooltip: "Ticket #"+item.tic_seq +" - Intervenant : "+item.presta_nom_prenom + " - Libellé : "+item.tic_c_label,
                },
                dates: moment.default(item.tis_date_planif).toDate(),
              },
            )

          }


        }
      );
      
      state.loaderEnabled = false;
    });


    return {
      state,
      clickTicket,zmyList
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>


